import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinearWithValueLabel from './LinearProgressWithLabel';

export default function Languages() {
  return (
    <Container  
      maxWidth="100%"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '4rem',
      }}
    >
      <Typography
        disableGutters
        variant='h4'
        sx={{
          fontWeight: '300',
          letterSpacing: '3.5px',
          textTransform: 'uppercase',
          color: '#000000'
        }}
      >
        Programming Languages
      </Typography>
      <LinearWithValueLabel progress={95} title="Golang" />
      <LinearWithValueLabel progress={90} title="Javascript" />
      <LinearWithValueLabel progress={85} title="Python" />
      <LinearWithValueLabel progress={75} title="C/C++" />
      <LinearWithValueLabel progress={75} title="HTML/CSS" />
    </Container>
  );
}