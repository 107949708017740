import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import {Link} from "react-router-dom";
import './ResponsiveAppBar.css';
import myResume from './files/michael_covarrubias_resume.pdf';

const pages = ['Home', 'About', 'Blog'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'black', boxShadow: 'none'}}>
      <Container maxWidth="xl" display='flex'>
        <Toolbar disableGutters sx={{float: 'inline-end'}}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="large"
              aria-label="menu dropdown"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none'},
                padding: '0',
                margin: '0'
              }}
            >
              {pages.map((page) => {
                if (page == 'Home') {
                  return (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Link key={page} to=''>{page}</Link>
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Link key={page} to={page}>{page}</Link>
                  </MenuItem>
                );
              }
              )}
              <MenuItem>
                <Button variant="outlined" href={myResume} target="_blank">Resume</Button>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <List sx={{padding: '0', margin: '0'}} component={Stack} direction='row'>
              {pages.map((page) => {
                if (page == 'Home') {
                  return (
                    <ListItem key={page}>
                      <Link
                        key={page}
                        onClick={handleCloseNavMenu}
                        to=''
                        sx={{ my: 2, color: '#ffffff', display: 'block' }}
                        className='michaelc-navbar-list-item-link'
                      >
                        {page}
                      </Link>         
                    </ListItem>
                  );
                }
                return (
                  <ListItem key={page}>
                    <Link
                      key={page}
                      onClick={handleCloseNavMenu}
                      to={page}
                      sx={{ my: 2, color: '#ffffff', display: 'block' }}
                      className='michaelc-navbar-list-item-link'
                    >
                      {page}
                    </Link>         
                  </ListItem>
                );
                }
              )}
              <ListItem>
                <a
                  key={"resume"}
                  className='michaelc-navbar-list-item-link'
                  href={myResume}
                  target='_blank'
                >
                    Resume
                </a>
              </ListItem>
            </List>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;