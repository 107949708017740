import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{height: "6px"}} size="lg" variant="determinate" color="inherit" {...props} />
      </Box>
      <Box sx={{ minWidth: 60 }}>
        <Typography variant="body2" color="text.secondary">{props.title}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {
  const [progress, setProgress] = React.useState(0);
  let timer = null;

  React.useEffect(() => {
    timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >=  props.progress ? props.progress : prevProgress + 5));
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (progress >= props.progress) {
    clearInterval(timer);
  }

  return (
    <Box sx={{ width: '80%' }}>
      <LinearProgressWithLabel value={progress} title={props.title} />
    </Box>
  );
}