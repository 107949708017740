import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';
import landing from './images/Landing.jpg';
import aboutme from './images/AboutMe.jpg';
import './Root.css';
import Languages from './Languages';
import CustomizedTimeline from './CustomizedTimeline';

export default function Root() {
  return (
    <Container  disableGutters maxWidth="100%" sx={{minHeight: '60vh'}}>
      <Container maxWidth="100%" disableGutters sx={{position: 'relative', height: '100vh'}}>
          <img src={landing} className="michaelc-landing-img"/>
          <div className="michaelc-landing-img-dimmer"/>
          <Box sx={{width: '100%', position: 'absolute', left: '0', top: '45%'}}>
            <Typography
              disableGutters
              variant='h2'
              sx={{
                fontWeight: '400',
                letterSpacing: '6.5px',
                textTransform: 'uppercase',
                color: '#ffffff'
              }}
            >
              Michael Covarrubias
            </Typography>
            <Typography
              disableGutters
              variant='h6'
              sx={{
                fontWeight: '300',
                letterSpacing: '3.5px',
                textTransform: 'uppercase',
                color: '#ffffff'
              }}
            >
              Software Engineer for React.JS Golang and Graphql
            </Typography>
          </Box>
      </Container>
      <Container  
        maxWidth="100%"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '3rem',
        }}
      >
        <Typography
          disableGutters
          variant="h4"
          sx={{
            fontWeight: '300',
            letterSpacing: '3.5px',
            textTransform: 'uppercase',
            color: '#000000'
          }}
        >
          About Me
        </Typography>
        <img src={aboutme} className="michaelc-about-me-img"/>
        <Typography sx={{width: "80%", paddingTop: "2rem"}} variant="body1" gutterBottom>
          I'm a full stack Software Engineer with 5 years of experinece. However, lately I've been working on my backend expertise.
          After recieving my Master's degree in Computer Science and Engineer, I started working at <a href="https://twitch.tv" target='_blank'>twitch.tv</a> where I gained further
          knowledge in developing cloud solutions utilizing AWS Infrastructure. When I'm not working on developing software I love to go hiking, work on my video editing skills and
          learning new dinner recipes. Welcome to my portfolio. Shoot me an email if you would like to know more about my career in software! :) 
        </Typography>
        <Grid container wrap='nowrap' alignItems='center' justifyContent='center' sx={{paddingTop: '2rem'}}>
          <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <Link href='https://github.com/mdcovarr' target='_blank' sx={{color: '#000000', margin:'auto 0.5rem'}}>
              <GitHubIcon fontSize='large' />
            </Link>
            <Link href='https://linkedin.com/in/mdcovarr' target='_blank' sx={{color: '#000000', margin:'auto 0.5rem'}}>
              <LinkedInIcon fontSize='large' />
            </Link>
            <Link href='https://www.instagram.com/michaelc_codes/?hl=en' target='_blank' sx={{color: '#000000', margin:'auto 0.5rem'}}>
              <InstagramIcon fontSize='large' />
            </Link>
            <Link href='' target='_blank' sx={{color: '#000000', margin:'auto 0.5rem'}}>
              <EmailIcon fontSize='large' />
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Languages />
      <Container
        maxWidth="100%"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '4rem',
        }}
      >
        <Typography
          disableGutters
          variant="h4"
          sx={{
            fontWeight: '300',
            letterSpacing: '3.5px',
            textTransform: 'uppercase',
            color: '#000000'
          }}
        >
          Life
        </Typography>
        <CustomizedTimeline />
      </Container>
    </Container>
  )
}